import React from "react";
import SNS from "../sns/SNS";
import style from "./hero.module.scss";

const Hero = () => (
  <div className={`${style.Hero}`}>
    <div className={style.description}>
      <p>
        Hi! I'm <span className={style.hightlight}>Alfredo Riveroll</span>, <span>a </span>
        <span className="highlight">front end developer</span>, <span className="highlight">team leader</span>
        <span>, and </span>

        <a className="highlight" href="https://mipon.org" target="_blank" rel="noopener noreferrer">
          <span>founder</span>
        </a>
        <span> dedicated to craft high performance websites. </span>
      </p>
    </div>
    <SNS />
  </div>
);

export default Hero;
