import React from "react";
import shortid from "shortid";
import mystyles from "./skill.module.scss";
// import { Spring } from "react-spring";

const Skill = props => (
  <div className={mystyles.container}>
    <div className={mystyles.skill}>
      <p className={mystyles.title}>{props.skill}</p>
      {props.skillNames.map((skillName, index) => {
        return (
          <div className={mystyles.li} key={shortid.generate()}>
            <img src={skillName.icon} className={mystyles.icon} alt="" />
            <p className={mystyles.technology}>{skillName.name}</p>
          </div>
        );
      })}
    </div>
  </div>
);

export default Skill;
