import React from "react";
import style from "./features.module.scss";

const Features = () => (
  <div className="ComponentSeparation">
    <h2 className={`${style.header} t2`}>Ways I can help the company right now:</h2>
    <div className={style.description}>
      <ul>
        <li className={style.item}>PHP Wordpress, theme development</li>
        <li className={style.item}>
          Building web applications using React JS and GraphQL
        </li>
        <li className={style.item}>Leading teams</li>
        <li className={style.item}>
          SEO, especially keyword research and technical SEO
        </li>
      </ul>
      <ul>
        <li className={style.item}>
          Writing business or system requirement definition
        </li>
        <li className={style.item}>
          Mapping and analyzing the business process
        </li>
        <li className={style.item}>Writing blog articles</li>
      </ul>
    </div>
  </div>
);

export default Features;
