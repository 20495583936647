import React from "react";
// import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Skills from "../components/skills";
import Features from "../components/features/Features";
import Hero from "../components/hero/Hero";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

    <div className="container">
      <Hero />
      <Features />
      <Skills />
    </div>
  </Layout>
);

export default IndexPage;
