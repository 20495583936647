import React from "react";
import style from "./sns.module.scss";
import { Link } from "gatsby";
import itwitter from "../../images/twitter.svg";
import ilinkedin from "../../images/linkedin.svg";
import ipdf from "../../images/pdf.svg";
import imail from "../../images/gmail.svg";

const SNS = () => (
  <ul className={style.SNS}>
    <li className={style.SNS_item}>
      <a href="https://s3-ap-northeast-1.amazonaws.com/mipon-media/CV_Riveroll+Alfredo.pdf"
        rel="nofollow noopener noreferrer" target="_blank" className={style.SNS_link}>
        <img src={ipdf} alt="" className={style.SNS_img} />
        <p className={style.SNS_title}>CV</p>
      </a>
    </li>
    <li className={style.SNS_item}>
      <a
        href="https://twitter.com/alfred_toh_"
        className={style.SNS_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={itwitter} alt="" className={style.SNS_img} />
        <p className={style.SNS_title}>Twitter</p>
      </a>
    </li>
    <li className={style.SNS_item}>
      <a href="mailto:alfrex.r@gmail.com" className={style.SNS_link} target="_top">
        <img src={imail} alt="" className={style.SNS_img} />
        <p className={style.SNS_title}>Email</p>
      </a>
    </li>

    <li className={style.SNS_item}>
      <a
        href="https://www.linkedin.com/in/alfredoriveroll/"
        target="_blank"
        rel="noopener noreferrer"
        className={style.SNS_link}
      >
        <img src={ilinkedin} alt="" className={style.SNS_img} />
        <p className={style.SNS_title}>Linkedin</p>
      </a>
    </li>
  </ul>
);

export default SNS;
